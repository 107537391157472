/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

* {
  scrollbar-color: #495cea #eaecff;
  scrollbar-width: thin;
  scrollbar-gutter: both-edges;
}

.mat-datetimepicker-calendar-header {
  background: #1e1ea0;
}

.spinner-backdrop,
.spinner-backdrop + .cdk-global-overlay-wrapper,
.spinner-panel {
  z-index: 1051;
}

/******************************
        Dialog Box
        Dialog Box
*******************************/
/* Globally remove the padding of dialog boxes */
.spinner-panel .mat-dialog-container {
  padding: 0 !important;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 1052;
}

/******************************
      Notification Popup
*******************************/

.cdk-global-overlay-wrapper {
  z-index: 1001 !important;
}

.mat-snack-bar-container {
  border-radius: 10px !important;
}

.mat-snack-bar-container .mat-simple-snackbar span {
  font-size: 17px !important;
  word-break: break-all;
}

.mat-snack-bar-container.danger {
  background: rgba(255, 0, 0, 1) !important;
}

.mat-snack-bar-container.warning {
  background: rgba(253, 178, 31, 1) !important;
}

.mat-snack-bar-container.success {
  background: rgba(60, 179, 113, 1) !important;
}

/******************************
          GENERIC
*******************************/
.hidden {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eaecff;
}

::-webkit-scrollbar-thumb {
  background: #495cea;
  border-radius: 10px;
  margin-right: 10px;
}

/******************************
       WYSIWYG Editor
*******************************/

:not(.cke_widget_wrapper_easyimage):not(.cke_widget_wrapper_easyimage-align-right) > .easyimage-align-right {
  margin-right: 5px !important;
}

:not(.cke_widget_wrapper_easyimage):not(.cke_widget_wrapper_easyimage-align-left) > .easyimage-align-left {
  margin-left: 5px !important;
}
